import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div className="flex justify-center">
        <div className="w-full max-w-2xl p-4">

          <h2 className="text-2xl font-bold mb-4 text-center">Our Rules and Policies</h2>
          <ul className="list-none pl-6 py-5 rounded-2xl bg-gray-800">
            <li><strong>Do Not Be Disrespectful</strong></li>
            <li><strong>No Refunds</strong> (unless stated otherwise)</li>
            <li><strong>Be Patient</strong> - We will respond within 24 hours</li>
            <li><strong>Always Vouch or Leave Feedback</strong></li>
            <li>If no warranty is expressly given, then there is none</li>
            <li><strong>No Advertising</strong> (Aside From Partners)</li>
            <li>For cryptocurrency trades, both customers and sellers must wait for blockchain confirmation</li>
            <li><strong>We Do Not Give Free Stuff</strong></li>
            <li><strong>Only Open Tickets For</strong> Buying/Selling/Support</li>
            <li><strong>We have the right to reserve service to anyone</strong></li>
            <li><strong>Refund/Replacement Will Only Be Issued IF IT WAS OUR FAULT</strong></li>
            <li>For lifetime warranty products, you must provide proof of purchase</li>
            <li>Proxies involve a 10% cut unless negotiated otherwise</li>
            <li><strong>No Warranty On Any Service If You Leave The Server/Don't Vouch</strong></li>
            <li>Use MM While Buying From a Proxy/Seller</li>
            <li>We are not responsible if a seller/proxy scams you</li>
            <li>We are not responsible for accounts that get security banned</li>
            <li>We are not responsible for incorrect payments; use the correct address and network</li>
            <li>All account purchases involve a 24-hour hold for security</li>
            <li>Leaving the server revokes warranty. Do not charge back.</li>
            <li>Do not sell us stolen goods</li>
            <li>Product warranties can change; it's your responsibility to stay updated</li>
            <li class="warning">3 warns = ban</li>
            <li>Follow all of <a href="https://discord.com/terms" class="hover:underline text-gray-400">Discord's Terms of Service</a></li>
            <li>All products are for educational purposes only</li>
            <li>We are not responsible for how our products are used</li>
            <li>Punishments are decided by staff</li>
            <li>Rules are subject to change</li>          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
