import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
const { bot } = require("../editable.json")

const Index = () => {
  const [sellers, setSellers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    fetch(`https://listing.noemt.dev/bot/${bot}/sellers`)
      .then((response) => response.json())
      .then((sellers) => {

        const activeSellers = sellers.filter(seller => seller.status !== "offline");
        const offlineSellers = sellers.filter(seller => seller.status === "offline");
        
        setSellers(activeSellers.concat(offlineSellers)); 
        
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-4">
            What We Offer ✨
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-xl font-semibold text-center rainbow-text">
                Skyblock Accounts & Profiles 🏝️
              </h3>
            </div>
            <div className="flex flex-col items-center bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-xl font-semibold text-center rainbow-text">
                Bedwars Accounts 🛏️
              </h3>
            </div>
            <div className="flex flex-col items-center bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-xl font-semibold text-center rainbow-text">
                Coins & Giveaways 🎁
              </h3>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-3xl font-bold text-center mb-8">
            Our Trusted Sellers
          </h2>

          {isLoading ? (
            <p className="text-center">Loading...</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              {sellers.map((seller) => (
                <div
                  key={seller.id}
                  className="bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300 relative"
                >
                  <img
                    src={seller.avatar}
                    alt={`${seller.username} avatar`}
                    className="w-24 h-24 rounded-full mx-auto mb-4 object-cover border-solid border-white border-2"
                  />
                  <h3 className="text-xl font-semibold text-center mb-2">
                    <a
                      className="hover:underline"
                      href={`https://discord.com/users/${seller.id}`}
                    >
                      {seller.username}
                    </a>
                  </h3>
                  <div
                    className={`absolute top-2 right-2 w-6 h-6 rounded-full status-${seller.status}`}
                  ></div>
                </div>
              ))}
            </div>
          )}
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Index;